import { NavLink } from "react-router-dom";
import { useState } from "react";


function Contact() {
  const [form, setForm] = useState({
    name: "",
    fatherName: "",
    email: "",
    gender: "",
  });
  const [error, setError] = useState({
    nameError: false,
    fatherNameError: false,
    emailError: false,
    genderError: false,
  });

  const submitForm = (e) => {
    console.log(form);
    ///
    ////Submit form
    

    if (
      form['name'] === ""      
    ) {
      setError({ ...error, nameError: true });  } 


    else if(
      form['fatherName'] === ""
    )
    {
      setError({ ...error, fatherNameError: true });  
    }


    else if(
      form['gender'] === ""
    )
    {
      setError({ ...error, emailError: true });   
    }

    else if(
      form['email'] === ""
    )
    {
      setError({ ...error, genderError: true }); 
    }

    

    return
  };
  const updateFormValues = (element) => {
    setError({
      nameError: false,
      fatherNameError: false,
      emailError: false,
      genderError: false,
    });

    if (element.target.name === "name") {
      console.log(element.target.value.length);

      let letters = /^[A-Za-z ]+$/;

      if (
        element.target.value.length > 20 ||
        !element.target.value.match(letters)
      ) {
        setError({ ...error, nameError: true });
        
        setForm({ ...form, name: "" });
        
      } else {
        setForm({ ...form, name: element.target.value });
      }
    } 
    else if (element.target.name === "fatherName") {
      let letters = /^[A-Za-z ]+$/;

      if (
        element.target.value.length > 20 ||
        !element.target.value.match(letters)
      ) {
        setError({ ...error, fatherNameError: true });
        
        setForm({ ...form, fatherName: "" });
        
      } else {
        setForm({ ...form, fatherName: element.target.value });
      }

      
    } else if (element.target.name === "gender") {

      if (
        !element.target.value == "male" ||
        !element.target.value =="female"
      ) {
        setError({ ...error, genderError: true });
        
        setForm({ ...form, gender: "" });
        
      } else {
        setForm({ ...form, gender: element.target.value });
      }

    } else if (element.target.name === "email") {

      let letters = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

      if (
        element.target.value.length == "" ||
        !element.target.value.match(letters)
      ) {
        setError({ ...error, emailError: true });
        
        setForm({ ...form, email: "" });
        
      } else {
        setForm({ ...form, email: element.target.value });
      }
    }
  };

  console.log(error);
  return (
    <>

     {/* Breadcrumb Start */}
     <div className="flex flex-col items-center py-12">
      <h2 className="text-4xl font-bold mb-4 secondaryColor uppercase"> Contact Us</h2>
      <div className="flex items-center">
        <span className="text-white text-base ">Home</span>
        <div className="h-2 w-2  secondaryBg mx-2"></div>
        <span className="text-white text-base">Contact</span>
      </div>
    </div>
 {/* Breadcrumb End */}

         <div className="flex flex-col md:flex-row contact-us faq-bg  px-24 py-24">
      <div className="w-full md:w-1/2  contact-info py-8 pr-28">
        <div className="text-lg font-bold uppercase supportColor">Reach out</div>
        <h2 className="text-2xl font-bold mt-2">
          Why not leave a message or give us a call?
        </h2>
        <p className="mt-4">
          Axcepteur sint occaecat atat non proident, sunt culpa officia deserunt
          mollit anim id est labor umLor emdolor
        </p>
        <div className="flex items-center mt-6">
          <div className="linear-gredient  p-2 mr-4 h-10 w-10 clip-path">
            <img src={require('../images/phone-icon.png')} alt="phone" />
          </div>
          <div>
            <p className="text-sm font-medium  mt-2">Phone</p>
            <p>123-456-7890</p>
          </div>
        </div>
        <div className="flex items-center mt-4 ">
          <div className="linear-gredient  p-2 mr-4 h-10 w-10 clip-path">
          <img src={require('../images/email2-icon.png')} alt="email" />
          </div>
          <div>
            <p className="text-sm font-medium">Email</p>
            <p>info@example.com</p>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="linear-gredient  p-2 mr-4 h-10 w-10 clip-path">
          <img src={require('../images/location-icon.png')} alt="location" />
          </div>
          <div>
            <p className="text-sm font-medium">Address</p>
            <p>123 Street, City, Country</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 contact-form  pl-16 pt-8 contactus-form mr-auto justify-end pt-89">
        <form>
          <input
            type="text"
            placeholder="Subject"
            className="w-full px-4 py-2 mb-4"
          />
          <div className="flex mb-4">
            <div className="w-1/2 mr-2">
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                className="w-full px-4 py-2"
              />
            </div>
            <div className="w-1/2 ml-2">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                className="w-full px-4 py-2"
              />
            </div>
          </div>
          <label htmlFor="message" className="sr-only">
            Message
          </label>
          <textarea
            id="message"
            rows="4"
            placeholder="Enter your message"
            className="w-full px-4 py-2 mb-4"></textarea>
          <button  className="submit">Submit</button>
        </form>
      </div>
    </div>
    </>
  );
}

export default Contact;
