import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function About() {
  return <>
 
 {/* Breadcrumb Start */}
 <div className="flex flex-col items-center py-12 bg-image-2">
      <h2 className="text-4xl font-bold mb-4 secondaryColor uppercase">About Us</h2>
      <div className="flex items-center">
        <span className="text-white text-base ">Home</span>
        <div className="h-2 w-2 rounded-full secondaryBg mx-2"></div>
        <span className="text-white text-base">About Us</span>
      </div>
    </div>
 {/* Breadcrumb End */}

  
<div className="flex flex-wrap bg-#0c0716 px-24">
      <div className="w-full md:w-1/2 py-4">
        <div className="text-sm font-bold supportColor">POWERFUL SERVICES</div>
        <h2 className="text-2xl font-bold mt-2 mb-4 uppercase text-white">Our Mission</h2>
        <img src={require('../images/about-img1.jpg')} alt="map" className="w-full" />
      </div>
      <div className="w-full md:w-1/2 pl-24 about-section p-8 mt-36 ">
        <h2 className="text-2xl font-bold text-white"><span className="supportColor text-base clear-both block">UNLEASH YOUR</span> FULL POTENTIAL</h2>
        <p className="text-white mt-2">
        True grace is beautiful in its imperfection, honest in its emotion, freed by its own frailty. True grace is beautiful in its imperfection, honest in its emotion, freed by its own frailty. True grace is beautiful in its imperfection, honest in its emotion, freed by its own frailty.
        </p>
      </div>
    </div>
    <div className="flex flex-wrap darkBlue-bg">
      <div className="w-full md:w-1/2 py-28 px-12">
        <div className="text-sm font-bold supportColor uppercase">What we offer</div>
        <h2 className="text-2xl text-white  font-bold mt-2 mb-4">SERVICES</h2>
        <div className="flex flex-wrap py-20">
          <div className="w-full md:w-1/2 pr-16 mt-4">
          <img src={require('../images/dimond-icon.png')} alt="Services" className="mr-16" />
            <h3 className="text-lg md:text-base font-bold mt-4 supportColor uppercase">Year Experience</h3>
            <p className="text-white mt-2">Lorem ipsum dolor sitamet const adipiscng Duis eletum sollicitudin is yaugue euismods</p>
          </div>
          <div className="w-full md:w-1/2 pr-16  mt-4">
            <img src={require('../images/user-setting.png')} alt="Setting" className="mr-16" />
            <h3 className="text-lg md:text-base font-bold mt-4 supportColor uppercase">Service 2</h3>
            <p className="text-white mt-2">Lorem ipsum dolor sitamet const adipiscng Duis eletum sollicitudin is yaugue</p>
          </div>

          <div className="w-full md:w-1/2 pr-16  mt-16">
            <img src={require('../images/crystal-icon.png')} alt="Setting" className="mr-16" />
            <h3 className="text-lg md:text-base font-bold mt-4 supportColor uppercase">Best PUBG Game</h3>
            <p className="text-white mt-2">Lorem ipsum dolor sitamet const adipiscng Duis eletum sollicitudin is yaugue</p>
          </div>


          <div className="w-full md:w-1/2 pr-16  mt-16">
            <img src={require('../images/setting.png')} alt="Setting" className="mr-16" />
            <h3 className="text-lg md:text-base font-bold mt-4 supportColor uppercase">Worldwide Client</h3>
            <p className="text-white mt-2">Lorem ipsum dolor sitamet const adipiscng Duis eletum sollicitudin is yaugue</p>
          </div>

        </div>
      </div>

      


      
      <div className="w-full md:w-1/2 about-section-2">
      <img src={require('../images/about-img2.jpg')} alt="map" className="w-full" />
      </div>
    </div>



    <div className="flex flex-wrap bg-black our-team px-32 py-32 ">
      <div className="w-full p-8">
        <div className="text-sm font-bold supportColor uppercase">Meet our Team</div>
        <h2 className="text-2xl font-bold mt-2 mb-4 text-white uppercase">Our Team</h2>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/4 pr-8 team-member">
            <div className="flex flex-col items-center">
            <img src={require('../images/team-1.jpg')} alt="map" className="w-full" />
              <h3 className="text-lg  uppercase font-bold mt-4 secondaryColor">Thomas Jakson</h3>
              <p className="text-lg md:text-xl text-white mt-2">Founder</p>
            </div>
            


          </div>
          <div className="w-full md:w-1/4 pr-8 team-member">
            <div className="flex flex-col items-center">
            <img src={require('../images/team-2.jpg')} alt=" " className="w-full" />
              <h3 className="text-lg uppercase font-bold mt-4 secondaryColor">David Cooper</h3>
              <p className="text-lg md:text-xl text-white mt-2">CEO</p>
            </div>
          </div>


          <div className="w-full md:w-1/4 pr-8 team-member">
            <div className="flex flex-col items-center">
            <img src={require('../images/team-3.jpg')} alt=" " className="w-full" />
              <h3 className="text-lg uppercase font-bold mt-4 secondaryColor">John Doe</h3>
              <p className="text-lg md:text-xl text-white mt-2">Director</p>
            </div>
          </div>
          <div className="w-full md:w-1/4 pr-8 team-member">
            <div className="flex flex-col items-center">
            <img src={require('../images/team-4.jpg')} alt=" " className="w-full" />
              <h3 className="text-lg uppercase font-bold mt-4 secondaryColor">Landon Robberts</h3>
              <p className="text-lg md:text-xl text-white mt-2">Manager</p>
            </div>
          </div>

          
         
        </div>
        <div className="flex justify-center mt-20">
      <div className="rounded-full border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center">
        <i class='fas fa-angle-left text-white text-2xl'></i>
        </div>
        <div className="rounded-full border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center ml-2">
          <i class='fas fa-angle-right text-white text-2xl'></i>
        </div>
      </div>

      </div>
     
    </div>

</>
    ;
}

export default About; 