import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import "./css/egame.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import FAQs from "./pages/FAQs";
import Map from "./pages/Maps/Maps";
import Bagpack from "./pages/BagpackPage/Bagpack";
import ContactForm from "./pages/ContactForm";
import Cursor from "./components/Cursor/Cursor";
import CustomHeader from "./components/CustomHeader/CustomHeader";
import SitePreloader from "./components/SitePreloader/SitePreloader";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import CustomFooter from "./components/CustomFooter/CustomFooter";
import BlogMainPage from "./pages/BlogMainPage/BlogMainPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import Statistics from "./pages/Statistics/Statistics";

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Simulating a delay to show the preloader for demonstration purposes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const isMapRoute = location.pathname === "/map";

  return (
    <>
      {loading ? (
        <SitePreloader />
      ) : (
        <div className="fade-in">
          {!isMapRoute && <CustomHeader />}
          <ScrollToTopButton />
          <Cursor />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/FAQs" element={<FAQs />} />
            <Route path="/Blog" element={<BlogPage />} />
            <Route path="/ScoreCard" element={<Statistics />} />
            <Route path="/BlogMain" element={<BlogMainPage />} />

            <Route path="/Map" element={<Map />} />
            <Route path="/Bagpack" element={<Bagpack />} />
            <Route path="/Contact" element={<ContactForm />} />
          </Routes>
          {!isMapRoute && <CustomFooter />}
        </div>
      )}
    </>
  );
}

export default App;
