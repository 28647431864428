import React from 'react';
import './BlogPage.css';
import post1Image from '../../images/post-1.jpg';
import post2Image from '../../images/post-2.jpg';
import author from '../../images/auhtor.png';




function BlogPage() {
  return (
    <>
    <div className="flex flex-col items-center py-12">
      <h2 className="text-4xl font-bold mb-4 secondaryColor uppercase">Blog</h2>
      <div className="flex items-center">
        <span className="text-white text-base ">Home</span>
        <div className="h-2 w-2 rounded-full secondaryBg mx-2"></div>
        <span className="text-white text-base">Blog</span>
      </div>
    </div>
    <section className="feature-bg">
    <section className="blog-section blog-page padding-top padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="post-details">
              <div className="post-thumb">
              <img src={post1Image} alt="img" />
              </div>
              <p>
                Financial experts support or help you to find out which way you can raise your funds more.
                Arkit a trusted name for providing assistants. Initially, their main objective was to ensure
                the service they provide these people are loyal to their industry, experienced, and professional.
              </p>
              <p>
                Unless you are the one who really cares about this, it is not terribly important. What all
                matters are how your hybrid mobile application development is going to work in the long run
                as no one will care about how it was built.
              </p>
              <blockquote>
                <i className="fas fa-quote-right"></i>
                There are no secrets to success. It is the result of preparation, hard work, and learning from
                failure.<span>- Winston Churchill.</span>
              </blockquote>
              <p>
                There are some big shifts taking place in the field of construction equipment mathematics.
                Starting with the integration of mathematics devices in vehicles right from the
                manufacturers, to the standardization and integration of mathematics data across various
                business functions, the future of mathematics has never seemed so full of potential for
                fleet-based businesses.
              </p>
              <ul className="post-details-gallery">
                <li><img src={post2Image} alt="img" /></li>
                <li><img src={post1Image} alt="img" /></li>
              </ul>

              <h3>Creative approach to every project</h3>
              <p>
                Financial experts support or help you to find out which way you can raise your funds more.
                Arkit a trusted name for providing assistants. Initially, their main objective was to ensure
                the service they provide these people are loyal to their industry, experienced, and professional.
              </p>
              <p>
                Another speaker, John Meuse, senior director of heavy equipment at Waste Management Inc.,
                echoed this, citing a cost-saving of $17,000 for the company when it cut idling time of a
                single Caterpillar 966 wheel loader.
              </p>
              <ul className="tags">
                <li><a href="#">Business</a></li>
                <li><a href="#">Marketing</a></li>
                <li><a href="#">Startup</a></li>
                <li><a href="#">Design</a></li>
              </ul>

              <ul className="post-navigation">
                <li>
                  <a >
                    <span><i className="las la-angle-left"></i>Previous</span>
                    How To Start Initiating An Startup In Few Days.
                  </a>
                </li>
                <li>
                  <a >
                    <span>Next<i className="las la-angle-right"></i></span>
                    Fresh Startup Ideas For Digital Business.
                  </a>
                </li>
              </ul>

              <div className="author-box">
                <div className="author-thumb">
                  <img src={author} alt="img" />
                </div>
                <div className="author-info">
                  <h3>Elliot Alderson</h3>
                  <p>Our versatile team is built of designers, developers, and digital marketers who all
                    bring unique experience.</p>
                  <ul className="social-icon">
                    <li><a href="#"><i className="lab la-facebook-f"></i></a></li>
                    <li><a href="#"><i className="lab la-twitter"></i></a></li>
                    <li><a href="#"><i className="lab la-instagram"></i></a></li>
                    <li><a href="#"><i className="lab la-behance"></i></a></li>
                  </ul>
                </div>
              </div>

              <h3 className="comment-title">Post Comments</h3>
              <ul className="comments-box">
                <li className="comment">
                  <div className="comment-inner">
                    <div className="comment-thumb"><img src={author}  alt="img" /></div>
                    <div className="comment-wrap">
                      <div className="comments-meta">
                        <h4>Ashton Porter <span>Jan 01, 2022 at 8:00</span></h4>
                      </div>
                      <div className="comment-area">
                        <p>You guys have put so much work, effort, and time while designing this
                          awesome theme I can see that passion when I incorporated it into my
                          website.</p>
                        <a href="#" className="reply">Reply</a>
                      </div>
                    </div>
                  </div>
                  <ul className="children">
                    <li className="comment">
                      <div className="comment-inner">
                        <div className="comment-thumb"><img src={author}  alt="img" /></div>
                        <div className="comment-wrap">
                          <div className="comments-meta">
                            <h4>Melania Trump <span>Jan 01, 2022 at 8:00</span></h4>
                          </div>
                          <div className="comment-area">
                            <p>The only thing I LOVE more than this theme and its incredible
                              options is the support team! They are freaking amazable!</p>
                            <a href="#" className="reply">Reply</a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="comment">
                  <div className="comment-inner">
                    <div className="comment-thumb"><img src={author}  alt="img" /></div>
                    <div className="comment-wrap">
                      <div className="comments-meta">
                        <h4>Elliot Alderson <span>Jan 01, 2022 at 8:00</span></h4>
                      </div>
                      <div className="comment-area">
                        <p>Outstanding quality in this theme, brilliant Effects and perfectly crafted
                          Code. We absolutely love it and can highly recommend ThemeEaster!</p>
                        <a href="#" className="reply">Reply</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <h3 className="comment-title">Leave a Comment</h3>
              <form action="#" method="post" className="comment-form form-horizontal">
                <div className="form-group row">
                  <div className="col-sm-6 padding-15">
                    <input type="text" id="name" name="name" className="form-control" placeholder="Name*" required="" />
                  </div>
                  <div className="col-sm-6 padding-15">
                    <input type="text" id="email" name="email" className="form-control" placeholder="Email*" required="" />
                  </div>
                  <div className="col-md-12 padding-15">
                    <textarea id="comment" name="comment" cols="30" rows="5" className="form-control comment" placeholder="Your Comment*" required=""></textarea>
                  </div>
                  <div className="col-md-12 padding-15">
                    <button id="submit" className="default-btn" type="submit">Submit Comment<span></span><span></span></button>
                    <div id="form-messages" className="alert" role="alert"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    </section>
    </>
  );
}

export default BlogPage;
