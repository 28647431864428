import React from 'react';
import './CustomFooter.css';


const CustomFooter = () => {
  return (
    <footer className="footer-section flex flex-col md:flex-row justify-center md:justify-between px-32 mx-auto py-10 mt-auto">
      <div className="w-full">
        <div className="row footer-items">
          {/* Footer Item 1 */}
          <div className="col-lg-3 col-sm-6 sm-padding">
            <div className="footer-item">
              <a className="brand" href="index.html"><img src={require('../../images/footer-logo.png')}  alt="logo" /></a>
              <p>Our success in creating business solutions is due in large part to our talented and highly committed team.</p>
              <ul className="social-list">
                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                <li><a href="#"><i className="fab fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
          {/* Footer Item 2 */}
          <div className="col-lg-3 col-sm-6 sm-padding">
            <div className="footer-item footer-list">
              <div className="widget-title">
                <h3>Useful Links</h3>
              </div>
              <ul className="footer-links">
                <li><a href="upcoming-matches.html">Tournaments</a></li>
                <li><a href="faq-page.html">Help Center</a></li>
                <li><a href="about.html">Privacy and Policy</a></li>
                <li><a href="about.html">Terms of Use</a></li>
                <li><a href="contact.html">Contact Us</a></li>
              </ul>
            </div>
          </div>
          {/* Footer Item 3 */}
          <div className="col-lg-3 col-sm-6 sm-padding">
            <div className="footer-item">
              <div className="widget-title">
                <h3>Contact Us</h3>
              </div>
              <ul className="footer-contact">
                <li><span>Location:</span>153 Williamson Plaza, Maggieberg, MT 09514</li>
                <li><span>Join Us:</span>Info@YourGmail24.com</li>
                <li><span>Phone:</span>+1 (062) 109-9222</li>
              </ul>
            </div>
          </div>
          {/* Footer Item 4 */}
          <div className="col-lg-3 col-sm-6 sm-padding">
            <div className="">
              <div className="widget-title">
                <h3>Newsletter Signup</h3>
              </div>
              <form action="#" className="subscribe-form" noValidate>
                <input className="form-control" type="email" name="EMAIL" placeholder="Your Email" required />
                <input type="hidden" name="action" value="mailchimpsubscribe" />
                <button className="submit">Subscribe Now</button>
                <div className="clearfix"></div>
                <div id="subscribe-result">
                  <div className="subscription-success"></div>
                  <div className="subscription-error"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
    </footer>
  );
};

export default CustomFooter;
