import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../../images/logo.png";
import "./CustomHeader.css";

const CustomHeader = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("home");
  const [menuItems] = useState([
    { id: "home", label: "Home", link: "/" },
    { id: "about", label: "About Us", link: "/About" },
    { id: "faqs", label: "FAQs", link: "/FAQs" },
    { id: "blog", label: "BLOG", link: "/blogmain" },
    { id: "backpack", label: "Backpack", link: "/bagpack" },
    { id: "maps", label: "Maps", link: "/map" },
    { id: "ScoreCard", label: "Scorecard", link: "/ScoreCard" },
    { id: "contact", label: "CONTACT", link: "/Contact" },
  ]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const activeMenuItem = menuItems.find(
      (menuItem) => menuItem.link === location.pathname
    );
    if (activeMenuItem) {
      setActiveTab(activeMenuItem.id);
    }
  }, [location, menuItems]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <header className="header mx-23">
      <div className="primary-header">
        <div className="">
          <div className="primary-header-inner">
            <div className="header-logo">
              <Link to="/">
                <img className="logo w-18" src={logoImage} alt="Logo" />
              </Link>
            </div>
            <div className="header-menu-wrap">
              <ul className="nav-menu">
                {menuItems.map((menuItem) => (
                  <li
                    key={menuItem.id}
                    className={activeTab === menuItem.id ? "active" : ""}
                  >
                    <Link
                      to={menuItem.link}
                      onClick={() => handleTabClick(menuItem.id)}
                    >
                      {menuItem.label}
                      <span></span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="header-right">
              <div className="search-icon dl-search-icon">
                <i className="las la-search"></i>
              </div>
              <Link className="default-btn" to="/join-team">
                Join Our Team
              </Link>

              <div className="mobile-menu-icon" onClick={toggleDrawer}>
                <div className="burger-menu">
                  <div className="line-menu line-half first-line"></div>
                  <div className="line-menu"></div>
                  <div className="line-menu line-half last-line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Drawer Menu */}
      <div className={`drawer-menu ${isDrawerOpen ? "open" : ""}`}>
        {/* Drawer Menu */}
        {/* Close button */}
        <button className="close-menu-btn" onClick={toggleDrawer}>
          <i className="fas fa-times"></i>{" "}
          {/* Assuming you're using Font Awesome for icons */}
        </button>
        <ul>
          {menuItems.map((menuItem) => (
            <Link
              to={menuItem.link}
              onClick={() => {
                handleTabClick(menuItem.id);
                toggleDrawer();
              }}
            >
              <li key={menuItem.id}>{menuItem.label}</li>
            </Link>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default CustomHeader;
