import { NavLink } from "react-router-dom";
//import { useForm } from "react-hook-form";
import clickSound from "./Sounds/click-sound.wav";
import { useState, useEffect } from "react";
import "./Bagpack.css";

function Bagpack() {
  const [selectedVestIndex, setSelectedVestIndex] = useState(0);
  const [selectedBagpack, setSelectedBagpack] = useState(0);
  const [selectedLoadData, setLoadData] = useState([]);
  const [selectedItemTotalCapacity, setItemTotalCapacity] = useState(0);
  const [sum, setSum] = useState(150);

  useEffect(() => {

    // // setLoadData([]);
    // // setItemTotalCapacity(0);
    // // Calculate sum whenever either selectedVestIndex or selectedBackpack changes
    // if (selectedVestIndex || selectedBagpack) {
    //   const newSum = sum +
    //     (selectedVestIndex?.capacity ?? 0) + (selectedBagpack?.capacity ?? 0);
    //   setSum(newSum);
    // } else {
      
    //   setSum(150);
    //    setLoadData([]);
    //     setItemTotalCapacity(0);
    // }
  }, [selectedVestIndex, selectedBagpack]);
  // const scrollImage = document.querySelector('.vest-bagpack-selection .vest-backpack-img');
  // const targetDiv = document.getElementsByClassName('detail-add');

  // scrollImage?.addEventListener('click', () => {
  //   debugger;
  // targetDiv.scrollIntoView({ behavior: 'smooth' });
  // scrollImage.classList.add('faded');
  // });


  // Function to handle count update (+/-)
const handleCountUpdate = (index, value) => {
  handleLoadData(index,value);
};

const handleVestClick = (index) => {
  const audio = new Audio(clickSound);
  audio.play();
  debugger;
  if (index.name === selectedVestIndex?.name) {

    const deductedVest = sum - (selectedVestIndex?.capacity ?? 0);
    setSum(deductedVest);
    setSelectedVestIndex(0); // Remove data if already selected
    setLoadData([]);
    setItemTotalCapacity(0);
  } else {
    const newSum = sum +
        (index?.capacity ?? 0) - (selectedVestIndex?.capacity ?? 0);
      setSum(newSum);
    setSelectedVestIndex(index);
    

    // Perform any additional logic or actions when a vest is clicked
  }
};

const handleBagPackClick = (backpack) => {
  const audio = new Audio(clickSound);
  audio.play();

  debugger;

  if (backpack.name === selectedBagpack?.name) {
    const deductedBagpack = sum - (selectedBagpack?.capacity ?? 0);
    setSum(deductedBagpack);
    setSelectedBagpack(0); // Remove data if already selected
    setLoadData([]);
    setItemTotalCapacity(0);
  } else {
    const newSum = sum + ((backpack?.capacity ?? 0)) - (selectedBagpack?.capacity ?? 0);
    setSum(newSum);
    setSelectedBagpack(backpack);
 
  }
};


  const handleLoadData = (data,countval=1) => {
    debugger;
    const audio = new Audio(clickSound);
    audio.play();
  
    const existingItemIndex = selectedLoadData.findIndex(
      (item) => item.name === data.name
    );

    let totalNonexistingItemIndex = 0;

    selectedLoadData.forEach((item) => {

      debugger;
      if (item.name != data.name) {
        const { capacity, count } = item;
        const itemTotalCapacity = capacity * count;
        totalNonexistingItemIndex += itemTotalCapacity;
      }
    });

  
    const itemCapacity = data.capacity;
    const itemCount = existingItemIndex !== -1 ? selectedLoadData[existingItemIndex].count + countval : 1;
    const itemTotalCapacity = (itemCapacity * itemCount)+totalNonexistingItemIndex;
    
    const newSum = selectedVestIndex? selectedVestIndex.capacity : 0 + selectedBagpack?.capacity;
    if (itemTotalCapacity<=sum ) {
      if (existingItemIndex === -1) {
        // Item not found, add it to the array with count = 1
         setLoadData((prevData) => [...prevData, { ...data, count: 1 }]);
         setItemTotalCapacity(itemTotalCapacity);

      } else {
        // Item found, update the count property
        const updatedLoadData = selectedLoadData.map((item, index) => {

          
          if (index === existingItemIndex) {
            const updatedCount = item.count + countval;
            if (updatedCount == 0) {
              return null; // Mark the item for removal
            } else {
              return { ...item, count: updatedCount };
            }
          }
          return item;
        });
    
        const filteredLoadData = updatedLoadData.filter((item) => item !== null);
        setLoadData(filteredLoadData);
        setItemTotalCapacity(itemTotalCapacity);

      }
    }
  };
  

  const Data = {
    backpacks: [
      {
        id: 1,
        image: "level1bagpack.png",
        name: "LEVEL 1 BACKPACK",
        capacity: 150,
      },
      {
        id: 2,
        image: "level2bagpack.png",
        name: "LEVEL 2 BACKPACK",
        capacity: 200,
      },
      {
        id: 3,
        image: "level3bagpack.png",
        name: "LEVEL 3 BACKPACK",
        capacity: 250,
      },
    ],
    vestData: [
      {
        name: "LEVEL 1 VEST",
        image: "level1Vest.png",
        capacity: 50,
      },
      {
        name: "LEVEL 2 VEST",
        image: "level2Vest.png",
        capacity: 50,
      },
      {
        name: "LEVEL 3 VEST",
        image: "level3Vest.png",
        capacity: 50,
      },
    ],
    Load: [
      {
        name: "AMMO 9MM",
        capacity: 3.75,
        image: "ammo_9mm.png",
        group: 1,
      },
      {
        name: "AMMO 45 ACP",
        capacity: 4,
        image: "Item_Ammo_45ACP_C.png",
        group: 1,
      },
      {
        name: "AMMO 5.56MM",
        capacity: 5,
        image: "Item_Ammo_556mm_C.png",
        group: 1,
      },
      {
        name: "AMMO 7.62MM",
        capacity: 6,
        image: "Item_Ammo_762mm_C.png",
        group: 1,
      },
      {
        name: "AMMO 300MAG",
        capacity: 10,
        image: "Item_Ammo_300Magnum_C.png",
        group: 1,
      },
      {
        name: "AMMO 12 GAUGE",
        capacity: 12.5,
        image: "ammo_12guage.png",
        group: 1,
      },
      {
        name: "Frag Grenade",
        capacity: 18,
        image: "frag.png",
        group: 2,
      },
      {
        name: "Stun Grenade",
        capacity: 12,
        image: "stun.png",
        group: 2,
      },
      {
        name: "Smoke Grenade",
        capacity: 14,
        image: "smoke.png",
        group: 2,
      },
      {
        name: "MOLOTOV",
        capacity: 16,
        image: "Item_Weapon_Molotov_C.png",
        group: 2,
      },
      {
        name: "BANDAGES",
        capacity: 2,
        image: "Item_Heal_Bandage_C.png",
        group: 4,
      },
      {
        name: "FIRST AID",
        capacity: 10,
        image: "Item_Heal_FirstAid_C.png",
        group: 4,
      },
      {
        name: "MED KIT",
        capacity: 20,
        image: "Item_Heal_MedKit_C.png",
        group: 4,
      },
      {
        name: "ENERGY DRINK",
        capacity: 4,
        image: "Item_Boost_EnergyDrink_C.png",
        group: 3,
      },
      {
        name: "ADRENALINE",
        capacity: 20,
        image: "Item_Boost_AdrenalineSyringe_C.png",
        group: 3,
      },
      {
        name: "PAINKILLER",
        capacity: 10,
        image: "Item_Boost_PainKiller.png",
        group: 3,
      }
    ],
  };

  const generateItemListHTML = () => {
    let items = Data.Load;
    const sortedItems = items.sort((a, b) => {
      // Sort by group first
      if (a.group !== b.group) {
        return a.group - b.group;
      }
      // If group is the same, sort by capacity
      return a.capacity - b.capacity;
    });

    
    const rows = [];
    let currentGroup = null;
    let currentRow = [];

    sortedItems.forEach((item, index) => {
      if (item.group !== currentGroup) {
        if (currentRow.length > 0) {
          rows.push(
            <div key={currentGroup} className="flex mb-2 gap-2 p-0">
              {currentRow}
            </div>
          );
        }
        currentGroup = item.group;
        currentRow = [];
      }

      currentRow.push(
        <div key={index} className="bg-gray-800 text-center w-full" onClick={() => handleLoadData(item)}
        >
          <img
            src={require(`./Images/${item.image}`)}
            alt={item.name}
            className="item-align-center my-2 load-img"
          />
          <p className="text-center mt-2 pb-2">{item.name}</p>
          <div className="grayBgColor h-8 pt-1 mt-auto">
            <img
              src={require("./Images/weight-icon.png")}
              alt="weight"
              className="item-align-center my-1 w-4"
            />
            <span className="text-lg text-white ml-2 ">
              Weight: {item.capacity}
            </span>
          </div>
        </div>
      );

      if (index === sortedItems.length - 1) {
        rows.push(
          <div key={currentGroup} className="flex gap-2 p-0">
            {currentRow}
          </div>
        );
      }
    });

    return <div>{rows}</div>;
  };

  return (
    <>
      {/* Breadcrumb Start */}
      <div className="flex flex-col items-center py-12">
        <h2 className="text-4xl font-bold mb-4 secondaryColor uppercase">
          Bag Pack
        </h2>
        <div className="flex items-center">
          <span className="text-white text-base ">Home</span>
          <div className="h-2 w-2 rounded-full secondaryBg mx-2"></div>
          <span className="text-white text-base">Bag Pack</span>
        </div>
      </div> 
      {/* Breadcrumb End  */}

      <div className="faq-bg grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 bagpack px-24 py-28 bagpack-bg">
        {/* First Column */}
        <div className="col-span-2 dual-sections-container">
          <div className="flex flex-col w-full mb-4 border-bg2 width1145" >
            <div className="flex flex-col  ">
              <div className="column-flex">
                <div class="header2">
                  <h4>VEST / BACKPACK</h4>
                </div>
                <div className="dual-sections-container">

                <div className="width145 bagpack-item justify-evenly mb-2 gap-2 vest-bagpack-selection">
                  {Data.vestData.map((vest, index) => (
                    <div
                      key={index}
                      className={`bg-gray-800 text-center w-full backpack-block ${
                        vest.name === selectedVestIndex?.name ? "click" : ""
                      }`}
                      onClick={() => handleVestClick(vest)}
                    >
                      <img
                        src={require(`./Images/${vest.image}`)}
                        alt="vest"
                        className="vest-backpack-img item-align-center my-2"
                      />
                      <p className="text-center mt-2">
                        {vest.name}
                      </p>

                      <div className="bgcolor h-8 mt-auto">
                        <span className="text-white">
                          CAPACITY: {vest.capacity}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="width145 bagpack-item  justify-evenly  gap-2 vest-bagpack-selection">
                  {Data.backpacks.map((backpacks, index) => (
                    <div
                      key={index}
                      className={`bg-gray-800 text-center w-full ${
                        backpacks.name == selectedBagpack?.name ? "click" : ""
                      }`}
                      onClick={() => handleBagPackClick(backpacks)}
                    >
                      <img
                        src={require(`./Images/${backpacks.image}`)}
                        alt="backpacks"
                        className="vest-backpack-img item-align-center my-2"
                      />
                      <p className="text-center mt-2">
                        {backpacks.name}
                      </p>

                      <div className="bgcolor h-8 mt-auto">
                        <span className="text-white">
                          CAPACITY: {backpacks.capacity}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                </div>
              </div>
            
            </div>
            <div class ="item-align-center bg-gray-800 text-center w-full backpack-block py-3"><h3>Ready to fight!</h3><div> v 1.12</div></div>
          </div>

          <div  className="flex flex-col w-full mb-4 border-bg2" >
          <div class={`bagpack-item border-bg2 animatedbg ${sum <= 0 ? 'disabled' : ''}`}>
            <div class="header2">
              <h4>Consumables</h4>
            </div>
            
            {generateItemListHTML()}</div>
          </div>

        
        

          {/* <div className="flex mb-2 gap-2 p-0">
      {Data.Load.map((item, index) => (
        <div key={index} className="bg-gray-800 text-center w-full">
          <img src={require(`./Images/${item.image}`)} alt="" className="item-align-center my-2" />
          <h3 className="text-center mt-2 text-lg pb-4">{item.name}</h3>
          <div className="supportBgColor h-8 mt-auto">
            <img src={require('./Images/weight-icon.png')} alt="weight" className="item-align-center my-1" />
            <span className="pl-4 text-lg text-white">Weight: {item.capacity}</span>
          </div>
        </div>
      ))}
    </div> */}

          {/* 
        <div className="flex mb-2 gap-2 p-0">
          <div className=" bg-gray-800 text-center w-full">
            <img src="" alt=" " className="item-align-center  my-2" />
            <h3 className="text-center mt-2 text-lg pb-4">Energy Drin</h3>
            <div className="supportBgColor h-8 mt-auto"><img src={require('./Images/weight-icon.png')} alt="weight" className="item-align-center my-1" />
              <span className="pl-4 text-lg text-white">Weight: 0.4</span>
             </div>
            
          </div>

          <div className=" bg-gray-800 text-center w-full">
            <img src={require('./Images/painkiller.png')} alt=" " className="item-align-center  my-2" />
            <h3 className="text-center text-lg mt-1 pb-4">Painkiller</h3>

            <div className="supportBgColor h-8 mt-auto"><img src={require('./Images/weight-icon.png')} alt="weight" className="item-align-center my-1" />
              <span className="pl-4 f text-lg text-white">Weight: 4mm</span>
             </div>
          </div>
          
        </div> */}

          {/* <div className="flex justify-evenly gap-2 mb-2">
          <div className=" bg-gray-800  text-center w-full flex flex-col ">
            <div>
            <img src={require('./Images/helmet.png')} alt="phone" className="item-align-center pt-4" />
            </div>
            <h3 className="text-center mt-auto text-lg">Three Level Helmet</h3>
            <div className="supportBgColor h-8 mt-auto"><img src={require('./Images/weight-icon.png')} alt="weight" className="item-align-center my-1" />
              <span className="pl-4 font-bold text-lg text-white">Weight: 4mm</span>
             </div>
          </div>

         <div className="fex flex-col justify-evenly">
         <div className=" bg-gray-800 text-center mb-2">
            <img src={require('./Images/gloves.png')} alt="phone" className="item-align-center" />
            <h3 className="text-center mt-2 text-lg">Gloves</h3>
            
          </div>

          <div className=" bg-gray-800 text-center flex-2 ">
            <img src={require('./Images/joger.png')} alt="phone" className="item-align-center" />
            <h3 className="text-center mt-2 text-lg">Shoes</h3>

           </div>
          

         </div>
        </div> */}

          {/* Add more tiles as needed */}
        </div>

        {/* Third Column */}
        <div className="bagpack-instruction col-span-1">
     

          <div className="border-bg2">

          <div className={`bg-gray-800 p-1 header2 textalignleft ${sum <= 0 ? 'disabled' : ''}`}>
          <div className="flex items-center px-3">
  <div className="w-10 ">
    <i className="fas fa-duotone fa-memory text-white fa-2x"></i>
  </div>
  <div className="pl-2">
    <h2 className="text-lg font-bold">Backpack Storage: </h2>
    <div className="text-sm">
      {selectedItemTotalCapacity}/<span>{sum}</span>
      
    </div>
    
  </div>
  
</div>
<div class="dual-sections-container">
<div className="flex items-center pd11">
          
          
          <div className="w-26">
            <img
              src={require("./Images/vest-purple.png")}
              alt=""
              className="w-full"
            />{" "}
          </div>
          <div className="pl-2">
            <div className="text-xs">
              {selectedVestIndex ? selectedVestIndex?.name : "N/A"}
            </div>

            <div className="text-xs">
              {selectedVestIndex ? selectedVestIndex.capacity : 0}
            </div>
          </div>
        </div>
       
        <div className="flex items-center pd11">
          <div className="w-26">
            <img
              src={require("./Images/bgpack-icon.png")}
              alt=""
              className="w-full"
            />{" "}
          </div>
          <div className="pl-2">
            <div className="text-xs">
              {selectedBagpack ? selectedBagpack?.name : "N/A"}
            </div>

            <div className="text-xs">
              {selectedBagpack ? selectedBagpack.capacity : 0}
            </div>
          </div>
        </div>
        </div>
          </div>
          <div className={`bg-gray-800 ${sum <= 0 ? 'disabled' : ''}`}>
            {/* Bagpack item */}

<div className="fixedstorageheight">
            {selectedLoadData.map((item, index) => (
  <div className="flex w-full items-center backpack-items mb-2" key={index}>
    <div className="item-icon-bg ">
      <img
        src={require(`./Images/${item.image}`)}
        alt={item.name}
        className="pt-2"
      />
    </div>
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex justify-between">
          <p className="ml-2">{item.name}</p>
          
        </div>
        <div className="mt-auto text-right pr-4 text-sm font-bold">
           
        <div className="flex  justify-end">

             <span
               className="cursor-pointer pr-3"
               onClick={() => handleCountUpdate(item, -1)}
             >
               <i className="fas fa-minus"></i>
             </span>
             <span className="text-sm font-bold">{item.count}</span>
             <span
               className="cursor-pointer pl-3"
               onClick={() => handleCountUpdate(item, 1)}
             >
               <i className="fas fa-plus"></i>
             </span>
             </div>
        </div>
      </div>
    </div>
  </div>
))}

</div>

{/* 

            <div className="flex w-full items-center backpack-items mb-2">
              <div className="item-icon-bg text-center">
                <img
                  src={require("./Images/first-aid.png")}
                  alt="first-aid"
                  className=" mt-4 pt-2"
                />
              </div>
              <div className="w-full">
                <div div className="flex flex-col">
                  <h3 className="ml-2 pl-4">Fisrt Aid Kit</h3>
                  <div className="mt-auto text-right pr-4 text-lg font-bold">
                    2
                  </div>
                </div>
              </div>
            </div>


            <div className="flex w-full items-center backpack-items mb-2">
              <div className="item-icon-bg text-center">
                <img
                  src={require("./Images/bandages-icon.png")}
                  alt="Bandages"
                  className=" mt-4 pt-2"
                />
              </div>
              <div className="w-full">
                <div div className="flex flex-col">
                  <h3 className="ml-2 pl-4">Bandages</h3>
                  <div className="mt-auto text-right pr-4 text-lg font-bold">
                    2
                  </div>
                </div>
              </div>
            </div>
          

            <div className="flex w-full items-center backpack-items mb-2">
              <div className="item-icon-bg text-center">
                <img
                  src={require("./Images/painkiller-icon.png")}
                  alt="Painkiller"
                  className=" mt-4 pt-2"
                />
              </div>
              <div className="w-full">
                <div div className="flex flex-col">
                  <h3 className="ml-2 pl-4">Painkiller</h3>
                  <div className="mt-auto text-right pr-4 text-lg font-bold">
                    2
                  </div>
                </div>
              </div>
            </div>
         

            <div className="flex w-full items-center backpack-items mb-2">
              <div className="item-icon-bg text-center">
                <img
                  src={require("./Images/enerdrink-icon.png")}
                  alt="Energy Drink"
                  className=" mt-4 pt-2"
                />
              </div>
              <div className="w-full">
                <div div className="flex flex-col">
                  <h3 className="ml-2 pl-4">Energy Drink</h3>
                  <div className="mt-auto text-right pr-4 text-lg font-bold">
                    2
                  </div>
                </div>
              </div>
            </div>
      

            <div className="flex w-full items-center backpack-items mb-2">
              <div className="item-icon-bg text-center">
                <img
                  src={require("./Images/bullet-iconpng.png")}
                  alt="5.56mm"
                  className=" mt-4 pt-2"
                />
              </div>
              <div className="w-full">
                <div div className="flex flex-col">
                  <h3 className="ml-2 pl-4">5.56mm</h3>
                  <div className="mt-auto text-right pr-4 text-lg font-bold">
                    2
                  </div>
                </div>
              </div>
            </div>
        

            <div className="flex w-full items-center backpack-items mb-2">
              <div className="item-icon-bg text-center">
                <img
                  src={require("./Images/scope-icon.png")}
                  alt="4x Soope"
                  className=" mt-4 pt-2"
                />
              </div>
              <div className="w-full">
                <div div className="flex flex-col">
                  <h3 className="ml-2 pl-4">4x Soope</h3>
                  <div className="mt-auto text-right pr-4 text-lg font-bold">
                    2
                  </div>
                </div>
              </div>
            </div>
     

            <div className="flex w-full items-center backpack-items">
              <div className="item-icon-bg text-center">
                <img
                  src={require("./Images/gloves-icon.png")}
                  alt="Gloves"
                  className=" mt-4 pt-2"
                />
              </div>
              <div className="w-full">
                <div div className="flex flex-col">
                  <h3 className="ml-2 pl-4">Gloves</h3>
                  <div className="mt-auto text-right pr-4 text-lg font-bold">
                    2
                  </div>
                </div>
              </div>
            </div> */}
            {/* End of repeated div */}
          </div>
</div>
        </div>
      </div>
    </>
  );
}

export default Bagpack;
