import React, { useEffect, useState } from 'react';
import './SitePreloader.css';

const SitePreloader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay to show the preloader for demonstration purposes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="site-preloader">
        <div className="spinner"></div>
      </div>
    );
  }

  return null;
};

export default SitePreloader;
