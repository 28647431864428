import React from "react";
import './BlogMainPage.css';
import post1Image from '../../images/post-1.jpg';
import post2Image from '../../images/post-2.jpg';
import post3Image from '../../images/post-3.jpg';
import post4Image from '../../images/post-4.jpg';
import postThumb1Image from '../../images/post-thumb-1.jpg';
import postThumb2Image from '../../images/post-thumb-2.jpg';
import postThumb3Image from '../../images/post-thumb-3.jpg';
import { useNavigate } from 'react-router-dom';



const BlogMainPage = () => {
  const navigate = useNavigate();
  const handlePostClick = () => {
    // Handle the click event
    // Perform any necessary logic before redirecting to the blog page
    // Example: navigate to the blog page
    navigate("/blog"); // Redirect to the "/blog" route
  };

  return (
    <>
      <div className="flex flex-col items-center py-12">
        <h2 className="text-4xl font-bold mb-4 secondaryColor uppercase">Blog</h2>
        <div className="flex items-center">
          <span className="text-white text-base ">Home</span>
          <div className="h-2 w-2 rounded-full secondaryBg mx-2"></div>
          <span className="text-white text-base">Blog</span>
        </div>
      </div>
      <section className="feature-bg">
        <section className="blog-section blog-page padding-top padding-bottom">
          <div className="px-8">
            <div className="row">
              <div className="col-lg-8 sm-padding">
                <div className="row grid-post">
                  <div className="col-md-6 padding-15">
                    <div className="post-card" onClick={handlePostClick}>
                      <div className="post-thumb">
                        <img src={post1Image} alt="post" className="w-full" />
                        <a href="#" className="post-category">Business</a>
                      </div>
                      <div className="post-content-wrap">
                        <ul className="post-meta">
                          <li><i className="las la-calendar"></i>Jan 01 2022</li>
                          <li><i className="las la-user"></i>Elliot Alderson</li>
                        </ul>
                        <div className="post-content">
                          <h3><a >How to Start initiating a startup in few days.</a></h3>
                          <p>Financial experts support or help you to find out which way you can raise your funds more...</p>
                          <a  className="read-more">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 padding-15">
                    <div className="post-card" onClick={handlePostClick}>
                      <div className="post-thumb">
                        <img src={post2Image} alt="post" className="w-full" />
                        <a href="#" className="post-category">Startup</a>
                      </div>
                      <div className="post-content-wrap">
                        <ul className="post-meta">
                          <li><i className="las la-calendar"></i>Jan 01 2022</li>
                          <li><i className="las la-user"></i>Elliot Alderson</li>
                        </ul>
                        <div className="post-content">
                          <h3><a >Financial experts support help you to find out.</a></h3>
                          <p>Financial experts support or help you to find out which way you can raise your funds more...</p>
                          <a  className="read-more">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Add more blog posts here */}
                </div>
                <div className="row grid-post">
                  <div className="col-md-6 padding-15">
                    <div className="post-card" onClick={handlePostClick}>
                      <div className="post-thumb">
                        <img src={post3Image} alt="post" className="w-full" />
                        <a href="#" className="post-category">Business</a>
                      </div>
                      <div className="post-content-wrap">
                        <ul className="post-meta">
                          <li><i className="las la-calendar"></i>Jan 01 2022</li>
                          <li><i className="las la-user"></i>Elliot Alderson</li>
                        </ul>
                        <div className="post-content">
                          <h3><a >How to Start initiating a startup in few days.</a></h3>
                          <p>Financial experts support or help you to find out which way you can raise your funds more...</p>
                          <a  className="read-more">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 padding-15">
                    <div className="post-card" onClick={handlePostClick}>
                      <div className="post-thumb">
                        <img src={post4Image} alt="post" className="w-full" />
                        <a href="#" className="post-category">Startup</a>
                      </div>
                      <div className="post-content-wrap">
                        <ul className="post-meta">
                          <li><i className="las la-calendar"></i>Jan 01 2022</li>
                          <li><i className="las la-user"></i>Elliot Alderson</li>
                        </ul>
                        <div className="post-content">
                          <h3><a >Financial experts support help you to find out.</a></h3>
                          <p>Financial experts support or help you to find out which way you can raise your funds more...</p>
                          <a  className="read-more">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Add more blog posts here */}
                </div>
                <ul className="pagination-wrap text-left mt-40">
                  <li><a href="#"><i className="las la-arrow-left"></i></a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#" className="active">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#"><i className="las la-arrow-right"></i></a></li>
                </ul>
              </div>
              <div className="col-lg-4 sm-padding">
                <div className="sidebar-widget">
                  <form action="" className="search-form">
                    <input type="text" className="form-control" placeholder="Search" />
                    <button className="search-btn" type="button"><i className="fa fa-search"></i></button>
                  </form>
                </div>
                <div className="sidebar-widget">
                  <div className="widget-title">
                    <h3>Categories</h3>
                  </div>
                  <ul className="category-list">
                    <li><a href="#">Business Strategy</a><span>23</span></li>
                    <li><a href="#">Project Management</a><span>05</span></li>
                    <li><a href="#">Digital Marketing</a><span>18</span></li>
                    <li><a href="#">Customer Experience</a><span>04</span></li>
                    <li><a href="#">Partnership System</a><span>15</span></li>
                  </ul>
                </div>
                <div className="sidebar-widget">
                  <div className="widget-title">
                    <h3>Recent Articles</h3>
                  </div>
                  <ul className="thumb-post">
                    <li>
                      <div className="thumb">
                        <img src={postThumb1Image} alt="thumb" className="w-full" />
                      </div>
                      <div className="thumb-post-info">
                        <h3><a href="#">How To Go About Initiating An Startup In Few Days.</a></h3>
                        <a href="#" className="date">Jan 01 2022</a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb">
                        <img src={postThumb2Image} alt="thumb" className="w-full" />
                      </div>
                      <div className="thumb-post-info">
                        <h3><a href="#">Financial Experts Support Help You To Find Out.</a></h3>
                        <a href="#" className="date">Jan 01 2022</a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb">
                        <img src={postThumb3Image} alt="thumb" className="w-full" />
                      </div>
                      <div className="thumb-post-info">
                        <h3><a href="#">Innovative Helping Business All Over The World.</a></h3>
                        <a href="#" className="date">Jan 01 2022</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-widget">
                  <div className="widget-title">
                    <h3>Tags</h3>
                  </div>
                  <ul className="tags">
                    <li><a href="#">business</a></li>
                    <li><a href="#">marketing</a></li>
                    <li><a href="#">startup</a></li>
                    <li><a href="#">design</a></li>
                    <li><a href="#">consulting</a></li>
                    <li><a href="#">strategy</a></li>
                    <li><a href="#">development</a></li>
                    <li><a href="#">tips</a></li>
                    <li><a href="#">Seo</a></li>
                  </ul>
                </div>
              </div>
            </div>


            
          </div>
        </section>
      </section>
    </>
  );
};

export default BlogMainPage;
