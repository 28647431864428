import React, { useState } from 'react';
import './ContentSlider.css';

const ContentSlider = ({ content }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };

  const insertHtmlAfterDiv = (item, html, className) => {
    if (React.isValidElement(item)) {
      const clonedElement = React.cloneElement(item, item.props, [...item.props.children]);
      const children = clonedElement.props.children;
  
      if (Array.isArray(children)) {
        const updatedChildren = children.map((child, childIndex) => {
          if (
            React.isValidElement(child) &&
            child.props.className &&
            child.props.className.includes(className)
          ) {
            const originalChildProps = child.props;
            const originalChildChildren = originalChildProps.children;
  
            return React.cloneElement(child, originalChildProps, [
              ...originalChildChildren,
              html,
            ]);
          } else {
            return child;
          }
        });
  
        return React.cloneElement(clonedElement, clonedElement.props, [...updatedChildren]);
      }
    }
  
    return item;
  };
    

  return (
    <div className="slider-container">
      <div className="slider" style={{ transform: `translateX(-${activeSlide * 100}%)` }}>
        {content.map((item, index) => (
          <div className="slide" key={index}>
            {insertHtmlAfterDiv(
              item,
              <div className="dots-container">

              <div className="dots">
                {content.map((_, dotIndex) => (
                  <div
                    key={dotIndex}
                    className={`dot ${dotIndex === activeSlide ? 'active' : ''}`}
                    onClick={() => handleDotClick(dotIndex)}
                  ></div>
                ))}
              </div>
              </div>
              ,
              'md:order-1'
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentSlider;
