import { useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function FAQs() {
  const [expandedSections, setExpandedSections] = useState([0]);

  // Function to handle click on section header
  const handleSectionClick = (index) => {
    if (expandedSections.includes(index)) {
      // Section is already expanded, collapse it
      const sectionIndex = expandedSections.indexOf(index);
      const newExpandedSections = [...expandedSections];
      newExpandedSections.splice(sectionIndex, 1);
      setExpandedSections(newExpandedSections);
    } else {
      // Section is collapsed, expand it
      setExpandedSections([...expandedSections, index]);
    }
  };

  return (
    <>
      {/* Breadcrumb Start */}
      <div className="flex flex-col items-center py-12">
        <h2 className="text-4xl font-bold mb-4 secondaryColor uppercase">FAQs</h2>
        <div className="flex items-center">
          <span className="text-white text-base ">Home</span>
          <div className="h-2 w-2 rounded-full secondaryBg mx-2"></div>
          <span className="text-white text-base">FAQs</span>
        </div>
      </div>
      {/* Breadcrumb End */}

      <div className="faq-bg py-32">
        <div className="faq-section w-full mx-auto px-32">
          <div className="text-left">
            <p className="mt-2 text-lg uppercase supportColor">Ask About the Game</p>
            <h2 className="text-2xl font-bold text-white uppercase">Frequently Asked Questions</h2>
          </div>
          <div className="mt-12">
            <div className="mx-auto">
              <div className="space-y-4 faq-panel">
                {faqData.map((faq, index) => (
                  <div
                    className={`faq-bdr py-18 ${
                      expandedSections.includes(index) ? "expanded" : ""
                    }`}
                    key={index}
                  >
                    <div
                      className={`flex items-center justify-between cursor-pointer ${
                        expandedSections.includes(index) ? "linear-gredient-tp-bt" : ""
                      }`}
                      onClick={() => handleSectionClick(index)}
                    >
                      <h3 className="text-xl font-bold text-white">{faq.question}</h3>
                      <i
                        className={`fa ${
                          expandedSections.includes(index) ? "fa-minus" : "fa-plus"
                        } text-white text-base`}
                      ></i>
                    </div>
                    <p
                      className={`faq-content mt-10 text-lg ${
                        expandedSections.includes(index) ? "" : "hidden"
                      }`}
                    >
                      {faq.answer}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const faqData = [
  {
    question: "HOW TO MEMBER?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum, felis nec dictum pulvinar, sapien nisl fermentum turpis, at lobortis libero leo sit amet leo. Fusce ut ante fringilla, tincidunt neque nec, dapibus enim. Donec volutpat interdum pellentesque. Mauris vitae diam vulputate, commodo lectus id, tempor nunc. Cras iaculis nibh vitae suscipit dignissim. Ut eget metus vitae magna sodales malesuada. Praesent sit amet augue pulvinar, cursus tortor ac, congue orci",
  },
  {
    question: "HOW I BUY A GAME?",
    answer: "The game can be played by...",
  },
  {
    question: "Pellentesque aliquam nibh eget nisi hendreri",
    answer: "Yes, the game has multiple levels...",
  },
  {
    question: "Pellentesque placerat tincidunt magna quis condimentum",
    answer: "Yes, the game has multiple levels...",
  },
  {
    question: "Integer vitae neque ipsum",
    answer: "Yes, the game has multiple levels...",
  },
];

export default FAQs;
