import logo from "../logo.svg";


import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState } from "react";
import React from "react";
import ContentSlider from "../components/ContentSlider/ContentSlider";
import { Link } from 'react-router-dom';



export default function Home() {

  
  
  const [numVal, setVal] = useState(5);
  const slides = [

    <div className="flex flex-col md:flex-row items-center">
    <div className="md:w-1/1 text-white order-2 md:order-1">
        <p className="text-xl uppercase pb-3">Best game in the world</p>
        <h1 className="text-4xl pb-3 uppercase text-white">Player unknown’s</h1>
        <h2 className="text-5xl uppercase font-bold mb-8 text-white">
          <span className="secondaryColor">Battel Ground</span> [eGame]
        </h2>
        <p className="text-lg font-bold uppercase pb-4">Like what you read? Sign up now.</p>
        <button className="default-btn">
          Play Now <i className="fa fa-play text-base ml-2" aria-hidden="true"></i>
        </button>
      </div>
        <div className="md:w-1/1 order-1 md:order-2 ml-auto text-right hidden sm:block">
          {/* Image */}
        
          <img src={require('../images/pubg-character.png')} alt=" " className="w-auto" />
        </div>
      </div>,

  
 
  <div className="flex flex-col md:flex-row items-center">
  <div className="md:w-1/1 text-white order-2 md:order-1">
      <p className="text-xl uppercase pb-3">Best game in the world</p>
      <h1 className="text-4xl pb-3 uppercase text-white">Player unknown’s</h1>
      <h2 className="text-5xl uppercase font-bold mb-8 text-white">
        <span className="secondaryColor">Battel Ground</span> [eGame]
      </h2>
      <p className="text-lg font-bold uppercase pb-4">Like what you read? Sign up now.</p>
      <button className="default-btn">
        Play Now <i className="fa fa-play text-base ml-2" aria-hidden="true"></i>
      </button>
    </div>
      <div className="md:w-1/1 order-1 md:order-2 ml-auto text-right hidden sm:block">
        {/* Image */}
      
        <img src={require('../images/snipercharacter.png')} alt=" " className="w-auto" />
      </div>
    </div>,

    
  <div className="flex flex-col md:flex-row items-center">
  <div className="md:w-1/1 text-white order-2 md:order-1">
      <p className="text-xl uppercase pb-3">Best game in the world</p>
      <h1 className="text-4xl pb-3 uppercase text-white">Player unknown’s</h1>
      <h2 className="text-5xl uppercase font-bold mb-8 text-white">
        <span className="secondaryColor">Battel Ground</span> [eGame]
      </h2>
      <p className="text-lg font-bold uppercase pb-4">Like what you read? Sign up now.</p>
      <button className="default-btn">
        Play Now <i className="fa fa-play text-base ml-2" aria-hidden="true"></i>
      </button>
    </div>
      <div className="md:w-1/1 order-1 md:order-2 ml-auto text-right hidden sm:block">
        {/* Image */}
      
        <img src={require('../images/game-charecters.png')} alt=" " className="w-auto md:mb-18 lg:mb-0" />
      </div>
    </div>
    // Add more slides here
  ];
  return (
    <div classNameName="App">
      
      <section className=" flex items-center " >
      <div className="paddingleftight p-2 px-0 mx-23">
        <ContentSlider content={slides} />

        
      </div>
    </section>

      <div className="cleafix"></div>

      
        <section className="feature-bg py-44">
        <div className="px-32 mx-auto">
          <p className="supportColor uppercase">What we offer</p>
          <h2 className="text-3xl uppercase font-bold text-white mt-2">Our Features</h2>
          <div className="flex flex-wrap justify-center mt-20 mb-20">
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 mb-8 feature-block">
              <div className="flex items-center mb-4 justify-center">
                <div className="flex-shrink-0 w-32 h-32 blue-gadial-gredient rounded-full flex items-center justify-center ">
                  <span className="about-feature-icon"> <img src={require('../images/feature-about.png')} alt="About us feature" /></span>
                </div>
              </div>
              <h3 className="text-2xl font-bold text-white text-center uppercase">About Us</h3>
              <p className="text-white text-center py-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec eros ut felis sollicitudin lacinia.</p>
              <div className="flex items-center mt-2 text-center justify-center">
                <div className="read-more-icon border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center circle">
                <p className=" text-white">Read More</p>
                <i class="fas fa-angle-right text-white text-2xl"></i>
                </div>
              </div>
            </div>
            <Link  to="/bagpack" className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-8 feature-block">
              <div className="flex items-center mb-4 justify-center">
                <div className="flex-shrink-0 w-32 h-32 blue-gadial-gredient rounded-full flex items-center justify-center ">
                <span className="backpack-feature-icon"> <img src={require('../images/feature-bagpack.png')} alt="Backpack feature" /></span>
                
                </div>
              </div>
              <h3 className="text-2xl font-bold text-white text-center uppercase">Back pack</h3>
              <p className="text-white text-center py-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec eros ut felis sollicitudin lacinia.</p>
              <div className="flex items-center mt-2 text-center justify-center">
              <div className="read-more-icon border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center circle">
                <p className=" text-white">Read More</p>
                <i class="fas fa-angle-right text-white text-2xl"></i>
                </div>
              </div>
            </Link>
            <Link  to="/ScoreCard"className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-8 feature-block">
              <div className="flex items-center mb-4 justify-center">
                <div className="flex-shrink-0 w-32 h-32 blue-gadial-gredient rounded-full flex items-center justify-center ">
                <span className="score-feature-icon"> <img src={require('../images/feature-score.png')} alt="Match Score Feature" /></span>
                </div>
              </div>
              <h3 className="text-2xl font-bold text-white text-center uppercase">Match Score</h3>
              <p className="text-white text-center py-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec eros ut felis sollicitudin lacinia.</p>
              <div className="flex items-center mt-2 text-center justify-center">
              <div className="read-more-icon border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center circle">
                <p className=" text-white">Read More</p>
                <i class="fas fa-angle-right text-white text-2xl"></i>

                </div>  
              </div>
            </Link>

            <Link to="/map" className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-8 feature-block">
              <div className="flex items-center mb-4 justify-center">
                <div className="flex-shrink-0 w-32 h-32 blue-gadial-gredient rounded-full flex items-center justify-center ">
                <span className="map-feature-icon"> <img src={require('../images/feature-map.png')} alt="Match Score Feature" /></span>
                  
                </div>
              </div>
              <h3 className="text-2xl font-bold text-white text-center uppercase">PubG Map</h3>
              <p className="text-white text-center py-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec eros ut felis sollicitudin lacinia.</p>
              <div className="flex items-center mt-2 text-center justify-center">
              <div className="read-more-icon border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center circle">
                <p className=" text-white">Read More</p>
                <i class="fas fa-angle-right text-white text-2xl"></i>
              </div>

              </div>
            </Link>
           
          </div>
        </div>
      </section>

      

    <section className="darkBlue-bg p-32 px-32">
      <div className="bluebox-bg rounded-lg p-20 blog">

      <div className="flex items-center mb-2">
        <p className="supportColor uppercase text-md mr-2">Talk about the game</p>
     
       
      </div>
      <h2 className="text-white font-bold text-2xl pb-8">Mauris quis condimentum tellus, quis ultricies est. Aliquam cursus arcu vitae arcu luctus viverra.</h2>
      <p className="text-white mb-4  text-md">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae
        massa est. Suspendisse fermentum augue eget massa varius lacinia.
        Curabitur aliquam ullamcorper urna, quis dignissim neque. In eu risus
        rhoncus, fermentum lectus eu, suscipit urna. Nullam lacinia turpis sit
        amet risus luctus, vitae volutpat mi finibus.
      </p>
      <button className="secondarBtn text-lg py-4` px-4 flex  items-center text-white">
        Read More
        <i class="fas fa-angle-right text-white"></i>
      </button>
      <div className="flex justify-end mt-4">
      <div className="rounded-full border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center">
        <i class='fas fa-angle-left text-white text-2xl'></i>
        </div>
        <div className="rounded-full border border-color-blue icon-circle-bg-color h-10 w-10 flex items-center justify-center ml-2">
          <i class='fas fa-angle-right text-white text-2xl'></i>
        </div>
      </div>

      </div>
    </section>
      
      
    

    </div>
  );
}
