import { useState, useRef } from "react";
import "./Statistics.css";
import * as XLSX from "xlsx";
import OCRButton from "../../components/OCRButton/OCRButton";

function Statistics() {
  const CalculatePoints = [
    {
      PointSystem: "2023 Point System",
      PlacementData: [
        {
          Placement: 1,
          Points: 10,
        },
        {
          Placement: 2,
          Points: 6,
        },
        {
          Placement: 3,
          Points: 5,
        },
        {
          Placement: 4,
          Points: 4,
        },
        {
          Placement: 5,
          Points: 3,
        },
        {
          Placement: 6,
          Points: 2,
        },
        {
          Placement: 7,
          Points: 1,
        },
        {
          Placement: 8,
          Points: 1,
        },
        {
          Placement: 9,
          Points: 0,
        },
        {
          Placement: 10,
          Points: 0,
        },
        {
          Placement: 11,
          Points: 0,
        },
        {
          Placement: 12,
          Points: 0,
        },
        {
          Placement: 13,
          Points: 0,
        },
        {
          Placement: 14,
          Points: 0,
        },
        {
          Placement: 15,
          Points: 0,
        },
        {
          Placement: 16,
          Points: 0,
        },
        {
          Placement: 17,
          Points: 0,
        },
        {
          Placement: 18,
          Points: 0,
        },
        {
          Placement: 19,
          Points: 0,
        },
        {
          Placement: 20,
          Points: 0,
        },
        {
          Placement: 21,
          Points: 0,
        },
        {
          Placement: 22,
          Points: 0,
        },
        {
          Placement: 23,
          Points: 0,
        },
        {
          Placement: 24,
          Points: 0,
        },
        {
          Placement: 25,
          Points: 0,
        },
      ],
      EliminationData: {
        Elimination: 1,
        Point: 1,
      },
    },
    {
      PointSystem: "2022 Point System",
      PlacementData: [
        {
          Placement: 1,
          Points: 15,
        },
        {
          Placement: 2,
          Points: 12,
        },
        {
          Placement: 3,
          Points: 10,
        },
        {
          Placement: 4,
          Points: 8,
        },
        {
          Placement: 5,
          Points: 6,
        },
        {
          Placement: 6,
          Points: 4,
        },
        {
          Placement: 7,
          Points: 2,
        },
        {
          Placement: 8,
          Points: 1,
        },
        {
          Placement: 9,
          Points: 1,
        },
        {
          Placement: 10,
          Points: 1,
        },
        {
          Placement: 11,
          Points: 1,
        },
        {
          Placement: 12,
          Points: 1,
        },
        {
          Placement: 13,
          Points: 0,
        },
        {
          Placement: 14,
          Points: 0,
        },
        {
          Placement: 15,
          Points: 0,
        },
        {
          Placement: 16,
          Points: 0,
        },
        {
          Placement: 17,
          Points: 0,
        },
        {
          Placement: 18,
          Points: 0,
        },
        {
          Placement: 19,
          Points: 0,
        },
        {
          Placement: 20,
          Points: 0,
        },
        {
          Placement: 21,
          Points: 0,
        },
        {
          Placement: 22,
          Points: 0,
        },
        {
          Placement: 23,
          Points: 0,
        },
        {
          Placement: 24,
          Points: 0,
        },
        {
          Placement: 25,
          Points: 0,
        },
      ],
      EliminationData: {
        Elimination: 1,
        Point: 1,
      },
    },
    {
      PointSystem: "Custom Point System",
      PlacementData: [
        {
          Placement: 1,
          Points: 15,
        },
        {
          Placement: 2,
          Points: 12,
        },
        {
          Placement: 3,
          Points: 10,
        },
        {
          Placement: 4,
          Points: 8,
        },
        {
          Placement: 5,
          Points: 6,
        },
        {
          Placement: 6,
          Points: 4,
        },
        {
          Placement: 7,
          Points: 2,
        },
        {
          Placement: 8,
          Points: 1,
        },
        {
          Placement: 9,
          Points: 1,
        },
        {
          Placement: 10,
          Points: 1,
        },
        {
          Placement: 11,
          Points: 1,
        },
        {
          Placement: 12,
          Points: 1,
        },
        {
          Placement: 13,
          Points: 0,
        },
        {
          Placement: 14,
          Points: 0,
        },
        {
          Placement: 15,
          Points: 0,
        },
        {
          Placement: 16,
          Points: 0,
        },
        {
          Placement: 17,
          Points: 0,
        },
        {
          Placement: 18,
          Points: 0,
        },
        {
          Placement: 19,
          Points: 0,
        },
        {
          Placement: 20,
          Points: 0,
        },
        {
          Placement: 21,
          Points: 0,
        },
        {
          Placement: 22,
          Points: 0,
        },
        {
          Placement: 23,
          Points: 0,
        },
        {
          Placement: 24,
          Points: 0,
        },
        {
          Placement: 25,
          Points: 0,
        },
      ],
      EliminationData: {
        Elimination: 1,
        Point: 1,
      },
    },
  ];

  const [numberOfTags, setNumberOfTags] = useState(2);
  const [games, setGames] = useState([
    {
      id: 1,
      map: "",
      uploadData: [],
    },
    // {
    //   id: 2,
    //   map: "",
    //   uploadData: {
    //     /* Your JSON object for game 2 */
    //   },
    // },
    // {
    //   id: 3,
    //   map: "",
    //   uploadData: {
    //     /* Your JSON object for game 2 */
    //   },
    // },
    // {
    //   id: 4,
    //   map: "",
    //   uploadData: {
    //     /* Your JSON object for game 2 */
    //   },
    // },
    // Add more game objects as needed
  ]);

  const [parentState, setParentState] = useState([]);

  const [selectedPointsOption, setSelectedPointsOption] = useState(
    CalculatePoints[0]
  );

  const handleNumberOfTagsChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10); // Convert value to integer
    setNumberOfTags(selectedValue);
  };

  const handleOCRComplete = (ocrResults, index) => {
    debugger;
    // Set the parent state with the OCR results received from OCRButton
    setParentState(ocrResults);
    games[index].uploadData.map((obj) => {
      debugger;

      /** For getting team postion using the tags */

      const ocrObj = ocrResults
        .map((ocrobj) =>
          ocrobj.playersdata.some((pobj) => pobj.name.includes(obj["tags"]))
            ? ocrobj.playersdata.filter((pdata) =>
                pdata.name.includes(obj["tags"])
              ).length >= numberOfTags
              ? ocrobj
              : null
            : null
        )
        .filter(Boolean)[0];

      const position = ocrObj?.position;

      obj["GroupPlacement"] = !obj["tags"] ? obj["tags"] : position;

      debugger;
      obj["PlacementPoints"] =
        !obj["tags"] || !obj["GroupPlacement"]
          ? ""
          : selectedPointsOption.PlacementData.filter(
              (pobj) => pobj.Placement == obj["GroupPlacement"]
            )[0]?.Points;

      const totalEliminations = !obj["tags"]
        ? obj["tags"]
        : ocrObj?.playersdata
            .map((obj) => obj.eliminations)
            .reduce((acc, current) => acc + current, 0);

      obj["TotalElimination"] = totalEliminations
        ? totalEliminations * selectedPointsOption.EliminationData.Point
        : totalEliminations;

      const totalpts = obj["PlacementPoints"] + obj["TotalElimination"];
      obj["TotalPoints"] = isNaN(totalpts) ? "" : totalpts;
    });
  };

  const handleMapChange = (gameId, selectedMap) => {
    setGames((prevGames) =>
      prevGames.map((game) => {
        if (game.id === gameId) {
          return { ...game, map: selectedMap };
        }
        return game;
      })
    );
  };

  const [form, setForm] = useState({
    name: "",
    fatherName: "",
    email: "",
    gender: "",
  });
  const [error, setError] = useState({
    nameError: false,
    fatherNameError: false,
    emailError: false,
    genderError: false,
  });

  const submitForm = (e) => {
    console.log(form);
    // Submit form logic here

    if (form.name === "") {
      setError((prevError) => ({ ...prevError, nameError: true }));
    } else if (form.fatherName === "") {
      setError((prevError) => ({ ...prevError, fatherNameError: true }));
    } else if (form.gender === "") {
      setError((prevError) => ({ ...prevError, genderError: true }));
    } else if (form.email === "") {
      setError((prevError) => ({ ...prevError, emailError: true }));
    }

    return;
  };

  const updateFormValues = (element) => {
    setError({
      nameError: false,
      fatherNameError: false,
      emailError: false,
      genderError: false,
    });

    if (element.target.name === "name") {
      let letters = /^[A-Za-z ]+$/;

      if (
        element.target.value.length > 20 ||
        !element.target.value.match(letters)
      ) {
        setError((prevError) => ({ ...prevError, nameError: true }));
        setForm((prevForm) => ({ ...prevForm, name: "" }));
      } else {
        setForm((prevForm) => ({ ...prevForm, name: element.target.value }));
      }
    } else if (element.target.name === "fatherName") {
      let letters = /^[A-Za-z ]+$/;

      if (
        element.target.value.length > 20 ||
        !element.target.value.match(letters)
      ) {
        setError((prevError) => ({ ...prevError, fatherNameError: true }));
        setForm((prevForm) => ({ ...prevForm, fatherName: "" }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          fatherName: element.target.value,
        }));
      }
    } else if (element.target.name === "gender") {
      if (
        element.target.value !== "male" &&
        element.target.value !== "female"
      ) {
        setError((prevError) => ({ ...prevError, genderError: true }));
        setForm((prevForm) => ({ ...prevForm, gender: "" }));
      } else {
        setForm((prevForm) => ({ ...prevForm, gender: element.target.value }));
      }
    } else if (element.target.name === "email") {
      let letters = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

      if (
        element.target.value.length === 0 ||
        !element.target.value.match(letters)
      ) {
        setError((prevError) => ({ ...prevError, emailError: true }));
        setForm((prevForm) => ({ ...prevForm, email: "" }));
      } else {
        setForm((prevForm) => ({ ...prevForm, email: element.target.value }));
      }
    }
  };

  const teamFileInputRef = useRef(null);
  const [teamfileName, setTeamFileName] = useState("");

  const teamHandleButtonClick = () => {
    teamFileInputRef.current.click();
  };

  const teamHandleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setTeamFileName(file.name);

      try {
        const jsonData = await teamReadExcelFile(file);

        // Update the uploadData property for each game
        setGames((prevState) =>
          prevState.map((obj) => ({
            ...obj,
            uploadData: jsonData,
          }))
        );

        // Do something with the jsonData (e.g., update state, send to server)
        console.log(jsonData);
      } catch (error) {
        console.error("Error reading Excel file:", error);
      }
    }
  };

  const teamReadExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          range: 4, // Start from the 4th row (0-based index)
          header: 1, // Treat the 4th row as the header
        });

        const result = [];

        jsonData.forEach((row, index) => {
          const slotNo = row[0] || null;
          const teamName = row[1] || "";
          const tags = row[2] || "";

          const formattedRow = {
            slotNo,
            teamName,
            tags,
          };

          result.push(formattedRow);
        });

        resolve(result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleSelectPointChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPointsOption(selectedValue);
    // Add other logic based on the selected value if needed
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Handle the file upload logic here
    console.log("Selected file:", file);
  };

  return (
    <>
      {/* Breadcrumb Start */}
      <div className="flex flex-col items-center py-12">
        <h2 className="text-4xl font-bold mb-4 secondaryColor uppercase">
          Score Card
        </h2>
        <div className="flex items-center">
          <span className="text-white text-base">Home</span>
          <div className="h-2 w-2  secondaryBg mx-2"></div>
          <span className="text-white text-base">Score Card</span>
        </div>
      </div>
      {/* Breadcrumb End */}

      <div className="flex flex-col md:flex-row faq-bg contact-us px-24 py-24">
        <div className="w-full md:w-1/2 contact-info py-8 pr-28">
          <div className="text-lg font-bold uppercase supportColor">
            Upload Match Score
          </div>
          <h2 className="text-3xl font-bold mt-2">Scorecard Upload </h2>

          <div className="flex items-center mt-24 justify-center sm:justify-start text-center">
            <div className="mr-4 w-full ">
              <img
                src={require("../../images/tournament-transparent.png")}
                alt=""
                className="w-full mx-auto"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 contact-form pl-16 pt-8 contactus-form mr-auto justify-end pt-89">
          <form>
            <div className="flex mb-2">
              <div className="w-1/2 mr-2">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  className="w-full px-4 py-2"
                  onChange={updateFormValues}
                />
              </div>

              <div className="w-1/2 ml-2">
                <label htmlFor="date" className="sr-only">
                  Date
                </label>
                <input
                  type="text"
                  id="date"
                  name="date"
                  placeholder="dd/mm/yyyy"
                  className="w-full px-4 py-2"
                  onChange={updateFormValues}
                />
              </div>
            </div>
            <div className="mb-4">
              {" "}
              <input
                type="file"
                ref={teamFileInputRef}
                style={{ display: "none" }}
                onChange={teamHandleFileChange}
              />
              <button
                type="button"
                className="hover:bg-blue-700 darkBlue-bg rounded-none text-gray-300 py-2 px-4 w-full mr-0 browse-field mt-10 text-left"
                onClick={teamHandleButtonClick}
              >
                <span class="mr-2">
                  <i class="fas fa-upload"></i>
                </span>{" "}
                {teamfileName ? teamfileName : "Team Sheet Upload"}
              </button>
            </div>
            <div className={teamfileName ? "not-disable" : "disabled"}>
              <div className="flex mb-3">
                <div class=" text-base font-bold uppercase supportColor">
                  <i class="fa fa-cog" aria-hidden="true"></i> Settings{" "}
                </div>
              </div>
              <div className="flex mb-4">
                <label htmlFor="score" className="sr-only">
                  Score
                </label>
                <select
                  id="score"
                  className="w-full px-4 py-3"
                  value={selectedPointsOption.PointSystem}
                  onChange={handleSelectPointChange}
                >
                  {CalculatePoints.map((option, index) => (
                    <option key={option.PointSystem} value={option}>
                      {option.PointSystem}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex mb-4">
                <label htmlFor="numberOfPlayer" className="sr-only">
                  Number of Players Required:
                </label>
                <select id="numberOfPlayer" className="w-full px-4 py-3">
                  <option>2 Player</option>
                  <option selected>3 Player</option>
                  <option>4 Player</option>
                </select>
              </div>

              <div className="flex mb-4">
                <div className="w-full ">
                  <label htmlFor="numberOfTag" className="sr-only">
                    Number of Tags Required
                  </label>
                  <select
                    id="numberOfTag"
                    className="w-full px-4 py-3"
                    value={numberOfTags}
                    onChange={handleNumberOfTagsChange}
                  >
                    <option value={2}>2 Tags</option>
                    <option value={3}>3 Tags</option>
                    <option value={4}>4 Tags</option>
                  </select>
                </div>
              </div>
              {games.map((game, index) => (
                <div className=" mb-4">
                  <div key={index} className="flex ">
                    <div className="w-1/2 mr-2 flex items-center justify-center gamelabelclass">
                      <label htmlFor={`game${game.id}`}>
                        {`Game ${game.id}`}
                      </label>
                    </div>

                    <div className="w-1/2 ml-2">
                      <label
                        htmlFor={`numberOfPlayer${game.id}`}
                        className="sr-only"
                      >
                        Map Name
                      </label>
                      <select
                        id={`numberOfPlayer${game.id}`}
                        className="w-full px-4 py-3"
                        value={game.map}
                        onChange={(e) =>
                          handleMapChange(game.id, e.target.value)
                        }
                      >
                        <option value="">Select Map</option>{" "}
                        {/* Set the value to empty string */}
                        <option>Erangel</option>
                        <option>Miramar</option>
                        <option>Sanhok</option>
                        <option>Vikendi</option>
                      </select>
                    </div>
                    <div className="w-1/2 ml-3">
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={handleFileChange}
                      />
                      <OCRButton
                        key={index}
                        onOCRComplete={(ocrResults) =>
                          handleOCRComplete(ocrResults, index)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}

              <div className="mb-4 mt--10">
                <span>
                  <a href="#">
                    <i class="fa fa-plus-circle mr-2" aria-hidden="true"></i>Add
                    more game
                  </a>
                </span>
              </div>

              <button
                className={
                  "submit" + !games[games.length - 1].uploadData
                    ? "disabled"
                    : "not-disable"
                }
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        {games.map((game, gameIndex) => (
          <table className="custom-table">
            {game.uploadData.length > 0 &&
              Object.keys(game.uploadData[0]).map((header, headerIndex) => (
                <th key={headerIndex}>{header}</th>
              ))}

            {game.uploadData?.map((dataItem, dataIndex) => (
              <tr key={`${gameIndex}-${dataIndex}`}>
                {Object.values(dataItem).map((cellValue, cellIndex) => (
                  <td key={cellIndex}>{cellValue}</td>
                ))}
              </tr>
            ))}
          </table>
        ))}
      </div>
    </>
  );
}

export default Statistics;
